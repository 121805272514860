<template>
  <div class="page">
    <div class="page-header">
      <div>
        <el-button type="primary" @click="exportItem" v-show="!isLAN">
          {{ $t('syslog.exportLog') }}
        </el-button>
      </div>
    </div>
    <div class="sub-header" v-show="isLAN">
      <el-button @click="isShowOperationLogs = true" :class="isShowOperationLogs? 'activeButton':''">{{ $t("device.operationLogs") }}</el-button>
      <el-button @click="isShowOperationLogs = false" :class="isShowOperationLogs? '':'activeButton'">{{ $t("device.systemLogs") }}</el-button>
    </div>
    <div class="page-main">
      <div v-show="isShowOperationLogs">
        <el-form :inline="true" :model="tabelSearch">
          <div class="page-tools">
            <el-form-item class="page-tools-right">
              <el-button type="text" class="btn-p0" @click="filter = !filter">
                {{ $t('filter') }}
                <i :class="filter ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
              </el-button>
            </el-form-item>
            <el-form-item class="page-tools-right keywords">
              <el-input
                  v-model="tabelSearch.keywords"
                  :placeholder="$t('syslog.keywordsPla')"
                  prefix-icon="el-icon-search"
                  clearable
                  @change="getTabelData"
              ></el-input>
            </el-form-item>
            <el-form-item class="page-tools-right">
              <el-select v-model="tabelSearch.title" @change="getTabelData">
                <el-option
                    v-for="item in titleList"
                    :key="language === 'en' ? item.TITLE_EN : item.TITLE"
                    :label="language === 'en' ? item.TITLE_EN : item.TITLE"
                    :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="page-tools-right">
              <el-select v-model="tabelSearch.status" @change="getTabelData">
                <el-option
                    v-for="item in syslogLevel"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="page-tools-right">
              <el-button type="primary" @click="exportItem" v-show="isLAN">
                {{ $t('syslog.exportLog') }}
              </el-button>
            </el-form-item>
          </div>
          <div class="page-tools" v-show="filter">
            <el-form-item class="page-tools-right">
              <select-time
                  :startTime="tabelSearch.beginTime"
                  :endTime="tabelSearch.endTime"
                  @change="changeTime"
              />
            </el-form-item>
          </div>
        </el-form>
        <table-pagination
            memory="syslog"
            ref="refTable"
            :height="pageTabelHeight"
            :tableData="tableData"
            :columnData="columnData"
            columnOptionShow
            :total="tabelTotal"
            :currentPage.sync="tabelSearch.currentPage"
            :pageSize.sync="tabelSearch.pageSize"
            @changeCurrentPage="getTabelData"
            @sort-change="tabelSortChange"
        >
        </table-pagination>
      </div>
      <div v-show="!isShowOperationLogs">
        <export-system-log/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import {deviceLogExport, deviceLogSearch, logExport, logSearch, logTypeList} from '@/api/syslog'
import {SelectTime, TablePagination} from '@/components'
import {downloadFile} from '@/plugins/methods'
import ExportSystemLog from "@/views/System/ExportSystemLog";

export default {
  name: 'Syslog',
  components: {
    TablePagination,
    SelectTime,
    ExportSystemLog
  },
  data() {
    return {
      isShowOperationLogs: true,
      filter: false,
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
        sortField: 'operateTime',
        sortOrder: 'desc',
        keywords: '',
        beginTime: null,
        endTime: null,
        status: '',
        title: '',
      },
      tabelTotal: 0,
      tableData: [],
      titleList: [
        {
          TITLE: '所有操作',
          TITLE_EN: 'All Operations',
          value: '',
        },
      ],
    }
  },
  computed: {
    ...mapState('account', ['loginInfo']),
    ...mapState('memory', ['sidebarSite', 'language']),
    ...mapGetters('dist', ['syslogLevel']),
    ...mapState('session', ['isLAN']),
    ...mapGetters('session', ['pageTabelHeight']),
    deviceId() {
      return this.$route.query.id
    },
    tabelParams() {
      let data = {...this.tabelSearch}
      data.site = this.sidebarSite
      return data
    },
    columnData() {
      return [
        {
          label: this.$t('syslog.username'),
          prop: 'operateUser',
          minWidth: 200,
          sortable: 'custom',
        },
        {
          label: this.$t('syslog.logContents'),
          prop: this.language === 'en' ? 'titleEn' : 'title',
          minWidth: 200,
          filter: (val, row) => {
            const noArgs = ['Login', 'Logout']
            return val + (row.args && !noArgs.includes(val) ? `:${row.args}` : '')
          },
        },
        {
          label: this.$t('syslog.ipAddress'),
          prop: 'ip',
          minWidth: 200,
          sortable: 'custom',
        },
        {
          label: this.$t('syslog.level'),
          prop: 'status',
          minWidth: 200,
        },
        {
          label: this.$t('syslog.opetatingTime'),
          prop: 'operateTime',
          minWidth: 200,
          sortable: 'custom',
          filter: 'dateFormat',
        },
      ]
    }
  },
  watch: {
    // 适配“设备-操作日志”跳转至“日志”未刷新的问题
    $route() {
      this.filter = false
      this.tabelSearch = {
        currentPage: 1,
        pageSize: 10,
        sortField: 'operateTime',
        sortOrder: 'desc',
        keywords: '',
        beginTime: null,
        endTime: null,
        status: '',
        title: '',
      }
      this.tabelTotal = 0
      this.tableData = []
      this.getTabelData()
    },
    sidebarSite() {
      this.getTabelData()
    },
  },
  created() {
    this.getLogTypeList()
    this.getTabelData()
  },
  methods: {
    // 修改查询时间
    changeTime(event) {
      this.tabelSearch.beginTime = event[0] || null
      this.tabelSearch.endTime = event[1] || null
      this.getTabelData()
    },
    // 获取日志类型
    getLogTypeList() {
      logTypeList()
        .then(({data}) => {
          this.titleList = [
            ...this.titleList,
            ...data.result.rows[0].map((e) => {
              e['value'] = e.TITLE
              return e
            }),
          ]
        })
        .catch(() => {
        })
    },
    // 获取数据
    getTabelData() {
      if (this.deviceId) this.tabelParams.deviceId = this.deviceId
      const api = this.deviceId ? deviceLogSearch : logSearch
      api(this.tabelParams)
        .then((res) => {
          this.tableData = res.data.result.rows[0].list // 表格数据
          this.tabelTotal = res.data.result.rows[0].total // 表格数据条目
          this.$refs.refTable.clearFilter() // 表格过滤清空
        })
        .catch(() => {
        })
    },
    // 排序
    tabelSortChange({order, prop}) {
      this.tabelSearch.sortOrder = order ? order : 'desc'
      this.tabelSearch.sortField = order ? prop : 'operateTime'
      this.getTabelData()
    },
    // 导出
    exportItem() {
      let timezone = this.loginInfo.timezone
      let param = {
        ...this.tabelParams,
        timezone: timezone.slice(1, timezone.length - 1)
      }
      if (this.deviceId) param.deviceId = this.deviceId
      const api = this.deviceId ? deviceLogExport : logExport
      api(param).then(res => downloadFile(res))
    },
  },
}
</script>

<style scoped lang="scss">
.sub-header {
  background-color: #E8E8E8;

  .el-button{
    background-color: #E8E8E8;
    border-width: 0;
    font-size: 18px;
    padding-left: 0;
    padding-right: 0;
    border-style: inset;
    border-bottom-width: 5px;
    border-radius: 0;
    margin-bottom: 24px;
    border-color: transparent;
  }
  .activeButton{
    border-color: #6b489d;
  }
}
</style>