import axios from '@/plugins/axios.js'

// 查询
export const logSearch = (params) => {
  let newParams = { ...params }
  newParams.site = params.site == '' ? null : params.site
  return axios.post('/log/operateLog', newParams)
}

// 查询（设备）
export const deviceLogSearch = (params) => {
  let newParams = { ...params }
  newParams.site = params.site == '' ? null : params.site
  return axios.post('/log/deviceLog', newParams)
}

// 查询
export const logTypeList = (params) => axios.get('/log/optList', { params })

// 导出
export const logExport = (params) => axios.post('/log/export',  params, { responseType: 'blob' })

// 导出设备日志
export const deviceLogExport = (params) => axios.post('/log/exportDeviceLog',  params, { responseType: 'blob' })

// 导出系统日志
export const systemLogExport = (params) => axios.post('/log/exportSystemLog',  params, { responseType: 'blob' })
