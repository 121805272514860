<template>
  <div class="root">
    <el-form :model="form">
      <el-radio-group v-model="form.type">
        <el-radio label="1">{{ $t('dist.exportSystemLog.today') }}</el-radio>
        <el-radio label="2">{{ $t('dist.exportSystemLog.week') }}</el-radio>
        <el-radio label="3">{{ $t('dist.exportSystemLog.all') }}</el-radio>
        <div class="pick-date">
          <el-radio :label="4">{{ $t('dist.exportSystemLog.custom') }}</el-radio>
          <div class="date">
            <el-date-picker
                v-model="form.customDate"
                :default-time="['00:00:00', '00:00:00']"
                :disabled="form.type!==4"
                :end-placeholder="$t('endTime')"
                :start-placeholder="$t('startTime')"
                type="daterange">
            </el-date-picker>
          </div>
        </div>


      </el-radio-group>
    </el-form>
    <el-button :disabled="!form.type || (form.type===4 &&(!form.customDate || form.customDate.length<2))" type="primary"
               @click="onSubmit">
      {{ $t('dist.exportSystemLog.submit') }}
    </el-button>
  </div>
</template>

<script>
import {systemLogExport} from "@/api/syslog";
import {downloadFile} from "@/plugins/methods";

export default {
  name: "ExportSystemLog",
  data() {
    return {
      form: {
        type: "2",
        customDate: [],
      }
    }
  },
  methods: {
    onSubmit() {
      let param = {
        ...this.form
      }
      systemLogExport(param).then(res => downloadFile(res))
    }
  }
}
</script>

<style lang="scss" scoped>
.root {
  padding-top: 10em;
  padding-left: 25%;
  padding-right: 25%;

  .el-form {
    .el-radio-group {
      width: 500px;
      display: flex;
      flex-direction: column;

      .el-radio {
        padding-top: 1em;
        padding-bottom: 1em;
      }

      .pick-date {
        .el-radio {

        }

        .date {
          display: inline-block;

          .el-col {
          }
        }
      }
    }
  }

  .el-button {
    margin-top: 15px;
    margin-left: 340px;
  }
}
</style>